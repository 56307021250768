/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
h1,
h2 {
  font-family: proxima-soft, sans-serif;
  font-style: normal;
}

.btn-link {
  color: #562703 !important;
}

.btn-primary {
  background-color: #fcce7f !important;
  border-color: #fcce7f !important;
  color: #562703 !important;
}

.btn-primary:hover,
.btn-primary:active {
  background-color: rgba(252, 206, 127, 0.8156862745) !important;
}

.btn-brown {
  border-color: #562703 !important;
  color: #562703 !important;
}